import React from 'react';
import { Article } from '../../components';
import { RG, AB, Gim, Kastel, BristolTuzla, HotelCentral, FranjevackiSamostanGG, AlipasinaDzamija } from './imports';
import './blog.css';

const Blog = () => {
  return (
    <div id='reference' className='bersia__reference section__padding'>
      <div className='bersia__reference-heading'>
        <h1 className='gradient__text-header'>Projekti</h1>
      </div>
      <div className='bersia__reference-container'>
        <div className='bersia__reference-container__groupA'>
          <Article imgUrl={RG} date={"Aktuelno"} title={"Roof Gardens"} description={"Podzemne hidroizolacije konstrukcije u tlu"}/>
        </div>
        <div className='bersia__reference-container__groupB'>
          <Article imgUrl={AB} date={"2023 - Završen"} title={"Asa Bolnica"} description={"Hidroizolacija i termoizolacija ravnog krova i unutarnje hidroizolacije"}/>
          <Article imgUrl={Gim} date={"2023 - Završen"} title={"5. Gimnazija"} description={"Rekonstrukcija krova sa zamjenom svjetlosnih traka(kupola)"}/>
          <Article imgUrl={Kastel} date={"2014"} title={"Tvrđava Kastel"} description={"Injektiranje zidina"}/>
          <Article imgUrl={BristolTuzla} date={"2014"} title={"UNi Bristol Tuzla"} description={"Hidroizolacije bazena"}/>
       </div>

       
      </div>
      <div className='bersia__reference-container-groupC'>
        <div className='bersia__reference-container__groupC'>
            <Article imgUrl={HotelCentral} date={"2006"} title={"Hotel Central"} description={"Podzemne hidroizolacije, unutarnje hidroizolacije, hidroizolacija bazena i ravni krov(hidroizolacija i termoizolacija)"}/>
            <Article imgUrl={FranjevackiSamostanGG} date={"1999"} title={"Franjevački Samostan Guča Gora"} description={"Prekid kapilarne vlage SKGI metodom"}/>
            <Article imgUrl={AlipasinaDzamija} date={"2006"} title={"Alipašina Džamija Sarajevo"} description={"Injektiranje pukotina na zidovima džamije"}/>
        </div>
      </div>

      {/* 
      <a href='/blog'>
        <div className='bersia__reference-btn'>
          <p> Više projekata  </p>
        </div>
      </a>
      */}
    </div>
  )
}

export default Blog
