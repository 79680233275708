import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLin, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/bersia_logo.svg';
import './navbar.css';




// BEM -> Block Element Modifier

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const Menu = () => (
    <>
    <p> <a href='#home' onClick={() => setToggleMenu(false)}>O nama</a> </p>
    <br />
    <p> <a href='#rad' onClick={() => setToggleMenu(false)}>Izvođenje Radova</a> </p>
    <p> <a href='#reference' onClick={() => setToggleMenu(false)}>Projekti</a> </p>
    <p> <a href='#materijali' onClick={() => setToggleMenu(false)}>Materijali</a> </p>

    <p> <a href='#footer' onClick={() => setToggleMenu(false)}>Kontakt</a> </p>
    </>
  )

  return (
    <div className='bersia__navbar'>
      <div className='bersia__navbar-links'>
        <div className='bersia__navbar-links_logo'>
          <img src={logo} alt="logo"></img>         
        </div>
        <div className='bersia__navbar-links_container'>
            <Menu />
        </div>
      </div>
      <div className='bersia__navbar-menu'>
        {toggleMenu
          ? <RiCloseLine color='#fff' size={27} onClick={() => setToggleMenu(false)}/>
          : <RiMenu3Line color='#fff' size={27} onClick={() => setToggleMenu(true)}/>
        }
        {toggleMenu && (
          <div className='bersia__navbar-menu_container scale-up-center'>
            <div className='bersia__navbar-menu_container-links'>
              <Menu />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Navbar
