import React, { useEffect, useState } from 'react'
import './backTop.css';

import FadeInOut from "./FadeInOut";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const BackTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    const [show, setShow] = useState(false);

    const goToBtn = () => {
        
        window.scrollTo({top:0, left:0, behavior: 'smooth'});
    };

    const listenToScroll = () =>{
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        
        if(winScroll == 0) {
            setIsVisible(false);
            setShow(false);
        }else {
            setIsVisible(true);
            setShow(true);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', listenToScroll);
    }, []);


    return (
        <div className='Wrapper'>
            <div>
                <FadeInOut show={show} duration={500}>
                    <div className= 'top-btn' onClick={goToBtn}>
                        <div className='arrow'>
                            <FontAwesomeIcon icon="arrow-down" className='spinArrow'/>
                        </div>
                    </div> 
                </FadeInOut>
            </div>
        </div>
    )
}

export default BackTop
