import React from 'react';
import { Navbar } from '../../components';

import { Header, WhatBERSIA, Features, Blog, Footer, Materijali, EmblaCarousel } from '../../containers';
import './homePage.css';

const OPTIONS = { loop: true }
const SLIDE_COUNT = 5
const SLIDES = Array.from(Array(SLIDE_COUNT).keys())


const HomePage = () => {
  return (
    <div>
        <div className='gradiant__bg'>
            <Navbar />
            <Header />
        </div>
        <WhatBERSIA/>
        <Features/>
        <EmblaCarousel slides={SLIDES} options={OPTIONS} />
        <Blog/>
        <Materijali/>
        <Footer/>
    </div>
  )
}

export default HomePage
