import React from 'react';
import waterDrops from '../../assets/water_drops.png';
import './header.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'




const Header = () => {
  return (
    <div className='bersia__header section__padding' id='home'>
      <div className='bersia__header-content'>
        <h1 className='gradient__text-header'>Bersia d.o.o.</h1>
        <p>Društvo ograničene odgovornosti BERSIA, Sarajevo osnovano je krajem 1996 godine, a početak rada u januaru 1997 godine. Društvo je osnovano kao nastavak ranije privredne aktivnosti Osnivača iz oblasti projektovanja i građenja, hidro i termo izolacija, kao i specijalnih građevinskih radova.</p>
      
        <div className='bersia__header-content__zavrseni-projekti'>
          <p>1000+ završenih projekata</p>
        </div>

        
      </div>
      <div className='bersia__header-image'>
          <img src={waterDrops} alt="kapljice" />
      </div>
    </div>
  )
}

export default Header
