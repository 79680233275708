import React from 'react';
import { Article, NavbarBack } from '../../components';
import { Footer } from '../../containers';

import { Slika1, Slika2, Slika3, Slika4, Slika5 } from './imports';
import './blogFull.css';

const BlogFull = () => {
  return (
    <div>
        <NavbarBack></NavbarBack>
        <div id='reference' className='bersia__reference section__padding'>
            
        <div className='bersia__reference-heading'>
            <h1 className='gradient__text-header'>Prethodni projekti</h1>
        </div>
        <div className='bersia__reference-container'>
            <div className='bersia__reference-container__groupB'>
            <Article imgUrl={Slika2} date={"01.01.2023"} title={"Naslov u dva reda i kratki opis objekta"}/>
            <Article imgUrl={Slika3} date={"01.01.2023"} title={"Naslov u dva reda i kratki opis objekta"}/>
            <Article imgUrl={Slika4} date={"01.01.2023"} title={"Naslov u dva reda i kratki opis objekta"}/>
            <Article imgUrl={Slika5} date={"01.01.2023"} title={"Naslov u dva reda i kratki opis objekta"}/>
            <Article imgUrl={Slika5} date={"01.01.2023"} title={"Naslov u dva reda i kratki opis objekta"}/>
            <Article imgUrl={Slika5} date={"01.01.2023"} title={"Naslov u dva reda i kratki opis objekta"}/>
            <Article imgUrl={Slika5} date={"01.01.2023"} title={"Naslov u dva reda i kratki opis objekta"}/>
            <Article imgUrl={Slika5} date={"01.01.2023"} title={"Naslov u dva reda i kratki opis objekta"}/>
        </div>
        </div>

        </div>
        <Footer></Footer>
    </div>
  )
}

export default BlogFull
