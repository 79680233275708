import React from 'react'


import { Blog, Features, Footer, Header, Possibility, WhatBERSIA, BlogFull, HomePage } from './containers';
import { CTA, Brand, Navbar, BackTop } from './components';
import './App.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckSquare, faArrowDown } from '@fortawesome/free-solid-svg-icons'

import { Routes, Route } from 'react-router-dom'

library.add(faCheckSquare, faArrowDown)



// <Brand />




const App = () => {
  return (
    <div className='App'>

      <BackTop />

      
      <Routes>
        <Route path='/' element={ < HomePage /> }></Route>
        <Route path='/blog' element={ < BlogFull /> }></Route>
      </Routes>


      
      

      
    </div>
  )
}

export default App
