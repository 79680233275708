import React from 'react';
import './whatBERSIA.css';
import { Feature } from '../../components';

const WhatBERSIA = () => {
  return (
    <div className='bersia__whatBERSIA section__margin'>
      <div className='bersia__whatBERSIA-feature'>
        <Feature title = "Djelatnost" text = "Osnovna djelatnost firme BERSIA d.o.o. Sarajevo je: Izvođenje svih vrsta hidroizolacionih i termoizolacionih sistema za sve vrste novih objekata, kao i sancije hidro i termo izolacija na postojećim objektima. Izvođenje specijalnih građevinskih radova, kao što su injektiranja u svrhu hidroizolacija, konstruktivnih ojačanja armirano betonskih konstrukcija, ugradnja dilatacionih spojnica i sl. "/>
      </div>
      <div className='bersia__whatBERSIA-heading'>
        <h1 className='gradient__text-header'> Više od 25 godina iskustva</h1>
        
      </div>  

      <div className='bersia__whatBERSIA-container'>
        <Feature title = "Savremeni Materijali" text = "BERSIA d.o.o. radi sa najsavremenijim visoko kvalitetnim materijalima poznatih svjetskih Proizvođača, provjerenog kvaliteta, sa odgovarajućim certifikatima meritornih Organizacija koje su zakonski ovlaštene za ispitivanje karakteristika i kvaliteta materijala."/>
        <Feature title = "Sistem Rada" text = "Tokom priprema i izvođenja radova, BERSIA d.o.o. pravi obimnu foto i video dokumentaciju prije, u toku radova i po završenom poslu koju pohranjuje u arhivi konkretnog posla koji je obavljen. Kopiju te foto i video dokumentacije BERSIA d.o.o. će na zahtjev dostaviti Naručiocu, odnosno Investitoru radova."/>
        <Feature title = "Garancije" text = "Stalnim unapređivanjem i usavršavanjem, BERSIA d.o.o. je u stanju i daje dugogodišnje garancije na izvedene radove i ugrađene materijale u skladu sa Zanonima, Propisima, Uputama i Smjernicama koje regulišu ovu oblast."/>
      </div>
    </div>
  )
}

export default WhatBERSIA
