import React from 'react';
import './features.css';
import { Feature } from '../../components';

const featuresData = [
  {
    title: 'Hidroizolacije',
    text: 'Naša firma pruža vrhunsku hidroizolaciju kao ključni korak ka zaštiti vaših objekata od nepoželjne vode i vlage koristeći najsavremenije materijale i tehnike kako bismo osigurali dugotrajnu zaštitu!',
  },
  {
    title: 'Termoizolacije',
    text: 'Termoizolacija je ključna stvar za očuvanje toplote i hlađenje u zgradama. Kvalitetna termoizolacija smanjuje gubitak energije i poboljšava energetsku efikasnost objekta.',
  },
  {
    title: 'Sanacije',
    text: 'Sanacije se odnose na popravke prethodne hidroizolacije ili termoizolacije. To su intervencije koje se izvode kako bi se otklonili problemi ili nedostaci u postojećim izolacionim slojevima.',
  },
  {
    title: 'Specijalni Radovi',
    text: 'Specijalni radovi obuhvataju različite intervencije i tehnike. Injektiranje je jedna od njih. To je postupak kojim se u materijal ubrizgava određena supstanca kako bi se poboljšala njegova čvrstoća, vodonepropusnost ili druge karakteristike.',
  },
  {
    title: 'Svjetlosne kupole',
    text: 'Svjetlosne kupole za ravne krovove su prozirni elementi koji se ugrađuju u ravne krovove kako bi omogućili prirodno svjetlo da prodre u unutrašnjost objekta. Osim što poboljšavaju osvjetljenje, svjetlosne kupole često imaju i termoizolacione karakteristike, čime doprinose energetskoj efikasnosti zgrade.',
  }
];

// nase usluge

const Features = () => {
  return (
    <div id="rad" className='bersia__features section__padding'>
      <div className='bersia__features-heading'>
        <h1 className='gradient__text-header'>Izvođenje radova firme BERSIA d.o.o.</h1>
        <p className='gradient__text-header'></p>
      </div>
      <div className='bersia__features-container'>
        {featuresData.map((item, index) =>(
          <Feature title = {item.title} text = {item.text} key={item.title + index}/>
        ))}
      </div>
    </div>
  )
}

export default Features
