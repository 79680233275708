import React from 'react';
import './article.css';


const Article = ({ imgUrl, date, title, description }) => {
  return (
    <div className='bersia__reference-container__article'>
      <div className='bersia__reference-container__article-img'>
        <img src={imgUrl} alt = "blog"/>
      </div>
      <div className='bersia__reference-container__article-content'>
        <div>
          <p>{date}</p>
          <h3>{title}</h3>
          <h4>{description}</h4>
        </div>
        <p></p>
      </div>
    </div>
  )
}

export default Article
