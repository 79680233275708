import React from 'react';

import './footer.css';
import bersiaLogo from '../../assets/bersia_logo.svg'

const Footer = () => {


  const handleButtonClick = () => {
    const newPageUrl = '../../pages/contact/ContactF/Contact.js';
    window.open(newPageUrl, '_blank');
  };


  return (
    <div id='footer' className='bersia__footer section__padding__footer'>
      <div className='bersia__footer-heading'>
        <h1 className='gradient__text-header'>Kontaktirajte nas</h1> 
      </div>

      {/*
      <div className='bersia__footer-btn'>
        <p onClick={handleButtonClick}>Kontaktirajte nas</p>
      </div>

      <BrowserRouter>
        <div className='bersia__footer-btn'>
          <nav>
            <ul>
              <li>
                <Link to="../../pages/contact">
                  <p>Kontaktirajte nas</p>
                </Link>
              </li>
            </ul>
          </nav>

          <Routes>
            <Route path="../../pages/contact">
              <Contact />
            </Route>
          </Routes>
          
        </div>
      </BrowserRouter>

      */}

      <section class="contact section " id="contact">
          <div class="conteiner">
            <div class="row">
                <form name="contact" method="POST" data-netlify="true" class="contact-form padd-15">
                    <div class="row">
                        <div class="form-item col-6 padd-15">
                            <div class="form-group">
                                <input type="name" name="name" class="form-control" placeholder="Ime"></input>
                            </div>
                        </div>
                        <div class="form-item col-6 padd-15">
                            <div class="form-group">
                                <input type="email" name="email" class="form-control" placeholder="Email"></input>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-item col-12 padd-15">
                            <div class="form-group">
                                <input type="text" name="text" class="form-control" placeholder="Naslov"></input>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-item col-12 padd-15">
                            <div class="form-group">
                                <textarea name="message" class="form-control" placeholder="Poruka"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-item col-12 padd-15">
                          <div class="bersia__footer-btn">
                            <button type="submit">Pošalji poruku</button>
                          </div>
                        </div>
                    </div>
                </form>
            </div>
          </div>
      </section>
      

      

  
   

      <div className='bersia__footer-links'>
        <div className='bersia__footer-links__logo'>
          <img src={bersiaLogo} alt='logo' />
        </div>


        <div className='bersia__footer-links__div'>
          <h4> Links </h4>
          <p>Link nije u funkciji</p>
          <p>Link nije u funkciji</p>
          <p>Link nije u funkciji</p>
          <p>Link nije u funkciji</p>
        </div>

        <div className='bersia__footer-links__div'>
          <h4> O firmi </h4>
          <p>Link nije u funkciji</p>
          <p>Link nije u funkciji</p>
          <p>Link nije u funkciji</p>
        </div>

        <div className='bersia__footer-links__div non-clickable'>
          <h4> Kontakt </h4>
          <p><a href="Tel:033 815 181">Telefon: +387 33 815 181</a></p>
          <p><a href="Tel:061 158 174">Mobitel: +387 61 158 174</a></p>
          <p><a href="mailto: bersia@bersia.ba">Email: bersia@bersia.ba</a></p>
        </div>

        
      </div>
  


      <div className='bersia__footer-copyright'>
          <p>Copyright © 2023 <a href='https://abdesignteam.netlify.app/'>ABDesignteam</a> - All rights reserved</p>
      </div>
    </div>
  )
}

export default Footer
