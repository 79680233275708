import React from 'react';
import { Article } from '../../components';
import { SIKA, STIG, POLYGLASS, FIBRAN, MAPEI } from './imports';
import './materijali.css';

const Blog = () => {
  return (
    <div id='materijali' className='bersia__reference section__padding'>
      <div className='bersia__reference-heading'>
        <h1 className='gradient__text-header'>Materijali</h1>
      </div>
      <div className='bersia__reference-container'>
        <div className='bersia__reference-container__groupB'>
          <Article imgUrl={SIKA} date={""} title={"SIKA"}/>
          <Article imgUrl={STIG} date={""} title={"STIG"}/>
          <Article imgUrl={POLYGLASS} date={""} title={"POLYGLASS"}/>
          <Article imgUrl={FIBRAN} date={""} title={"FIBRAN"}/>
          <Article imgUrl={MAPEI} date={""} title={"MAPEI"}/>
       </div>
      </div>
      {/*
      <a href='/blog'>
        <div className='bersia__reference-btn'>
          <p> Više projekata  </p>
        </div>
      </a>
      */}
    </div>
  )
}

export default Blog
