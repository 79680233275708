import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLin, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/bersia_logo.svg';
import './navbarBack.css';

const Menu = () => (
  <>
  <p> <a href='/'>Nazad</a> </p>
  </>
)

// BEM -> Block Element Modifier

const NavbarBack = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className='bersia__navbar'>
      <div className='bersia__navbar-links'>
        <div className='bersia__navbar-links_logo'>
          <img src={logo} alt="logo"></img>         
        </div>
        <div className='bersia__navbar-links_container-back'>
            <Menu />
        </div>
      </div>
      
    </div>
  )
}

export default NavbarBack
